import { Box } from '@chakra-ui/core'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import { embeddedEntryRenderer } from '~/cms/rich-text/renderers'

/**
 * Creates a rich text options object for rendering Contentful rich text
 * as react components. Pass in your own options to customize the rich text
 * for a specific context.
 *
 * Docs: https://www.contentful.com/developers/docs/javascript/tutorials/rendering-contentful-rich-text-with-javascript/#customized-rendering-in-react
 *
 * @param opts Customizes the components rendered by Contentful rich text
 */
export default function makeRichTextOptions(opts?: Partial<Options>): Options {
  return {
    renderMark: {
      ...opts?.renderMark,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => <Body>{children}</Body>,
      [BLOCKS.LIST_ITEM]: (_, children) => <Box as="li">{children}</Box>,
      [BLOCKS.UL_LIST]: (_, children) => (
        <Box as="ul" textAlign="left">
          {children}
        </Box>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: embeddedEntryRenderer,
      ...opts?.renderNode,
    },
    renderText: opts?.renderText || undefined,
  }
}
