import { Node } from '@contentful/rich-text-types'
import { rem } from 'design'
import {
  ITestimonial,
  Testimonial,
} from 'design/components/Typography/TypographyCompositions'
import React from 'react'
import Disclaimer from '~/cms/components/disclaimer'

export type SupportedContentType =
  | 'ContentfulDisclaimer'
  | 'ContentfulTestimonial'

export const embeddedEntryRenderer = (node: Node) => {
  switch (node.data?.target?.__typename as SupportedContentType) {
    case 'ContentfulDisclaimer': {
      const { body } = (node.data?.target?.body as { body?: string }) || {}
      if (!body) {
        return null
      }
      return <Disclaimer>{body}</Disclaimer>
    }
    case 'ContentfulTestimonial': {
      const { statement, name, affiliation } =
        (node.data?.target as Partial<ITestimonial>) || {}
      if (!statement || !name || !affiliation) {
        return null
      }
      return <Testimonial py={rem(16)} {...{ statement, name, affiliation }} />
    }
    default:
      // TODO: Surface this error in development; currently it only renders in netlify
      console.error(
        'Unexpected contentful content type. Make sure `contentful_id` and `__typename` are present in the graphql query for references.',
        node
      )
  }
}
