import { rem } from 'design'
import { ITypographyProps, Tiny } from 'design/components/Typography/Typography'
import React from 'react'

/**
 * Matches a prefixed asterisk for rendering with a <sup> tag
 */
const REGEXP_DISCLAIMER = /(\*+)?(.+)/

export interface IDisclaimer extends JSX.Element {}
type IDisclaimerProps = {
  children: string
} & ITypographyProps

/**
 * A block of legal text that can be referenced independently or in rich text
 */
export default function Disclaimer({
  children,
  ...props
}: IDisclaimerProps): IDisclaimer | null {
  if (!children) {
    return null
  }

  const match = children.match(REGEXP_DISCLAIMER)
  if (!match || match.length !== 3) {
    return null // No text
  }

  const prefix = match[1]
  const content = match[2]

  return (
    <Tiny data-what="disclaimer" pt={rem(12)} {...props}>
      <sup>{prefix}</sup>
      {content}
    </Tiny>
  )
}
