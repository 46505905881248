import { Box, BoxProps } from '@chakra-ui/core'
import { BodySmaller, Tiny } from 'design/components/Typography/Typography'
import React from 'react'

export type ITestimonial = {
  statement: React.ReactChild
  name: React.ReactChild
  affiliation: React.ReactChild
}
export const Testimonial: React.FC<ITestimonial & BoxProps> = ({
  statement,
  name,
  affiliation,
  ...props
}) => {
  return (
    <Box data-what="testimonial" {...props}>
      <BodySmaller data-what="testimonial-statement">
        <strong>{statement}</strong>
      </BodySmaller>

      <Tiny color="bb.stone" data-what="testimonial-name" textAlign="right">
        {name}, {affiliation}
      </Tiny>
    </Box>
  )
}
